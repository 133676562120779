import Vue from 'vue';
import Vuex from 'vuex';
import Cookies from 'js-cookie';

Vue.use(Vuex);

export default new Vuex.Store({
    strict: true,
    state: {
        //  backend_url: 'http://127.0.0.1:8001',
        backend_url: 'https://inv.andmiedu.uz/b',
        // backend_url: 'https://b-avto-tashish.uzautomotors.com',
        access_token: Cookies.get('access_token') || 'null',
        locale: Cookies.get('locale') || 'uz_latin',
        user: null,
        permissions: null,
        errors: [],
    },
    getters: {
        // checkPermission: (state) => (permission) => {
        //     let permissions = state.permissions;
        //     if (permissions)
        //           return permissions.find(v => v == permission);
        //     return false;
        // },
    },
    mutations: {
        setUser: (state, arg) => {
            state.user = arg;
        },
        // setPermissions: (state, arg) => {
        //     state.permissions = arg;
        // },
        setAccessToken: (state, arg) => {
            Cookies.set('access_token', JSON.stringify(arg), 1);
            state.access_token = JSON.stringify(arg);
        },
    },
    actions: {
        setLocale: (state, arg) => {
            Cookies.set('locale', arg);
        },
        // setPermissions: (context, arg) => {
        //     context.commit('setPermissions', arg)
        // },
        setUser: (context, arg) => {
            context.commit('setUser', arg);
        },
        setAccessToken: (context, arg) => {
            context.commit('setAccessToken', arg);
        },
    },
    modules: {},
});
