const ru = {
  choose: 'Выберите файл',
  upload: 'Загрузить',
  main_page: 'Главная',
  admin_panel: 'Админ панел',
  drawings: 'Чертежи',
  sertifikat: 'Сертификация',
  standart: 'Стандарт',
  loadingText: 'Загрузка',
  noDataText: 'Информация не найдена',
  tr: '№',
  incorrect_login: 'Логин или пароль неверно',
  rememberMe: 'Запомнить',
  search: 'Поиск',

  input: {
    required: 'Это поле обязательно',
    text: 'Введите текст',
  },
  user: {
    index: 'Пользователи',
    tbn: 'Табельный номер',
    username: 'Имя пользователя',
    name: 'ФИО',
    email: 'Email',
    add: 'Добавить',
    created: 'Добавлен',
    updated: 'Обновлено',
    logs: 'Логи',
    system: 'Система',
    controller: 'Действия',
    actions: 'Запрос',
    logtime: 'Время',
  },
  drawing: {
    upload: 'Загрузить MPL',
    mplupload: 'Загрузить новый MPL',
    name: 'ФИО',
    email: 'Email',
    add: 'Добавит',
    created: 'Добавлен',
    updated: 'Обновлено',
  },
  mpl: {
    partnum: 'Номер детали',
    product: 'Продукт',
    year: 'Год',
    qty: 'Кол-во',
    version: 'Версия',
    compare: 'Changelog',
    funpartmod: 'Функциональное имя',
    partname: 'Имя детали',
    drawingno: 'Номер чертежа',
    dwgrevdate: 'Дата чертежа',
    engineername1: 'Имя Инженера1',
    engineergroup1: 'Группа Инженера1',
    engineername2: 'Имя Инженера2',
    engineergroup2: 'Группа Инженера2',
    ewo: 'EWO',
    model: 'Модель',
    options: 'Опции',
    firstyear: 'Год начала',
    lastyear: 'Год завершения',
    pompl: 'по MPL',
    inmpl: 'MPL',
    inftp: 'FTP',
  },
  part: {
    search: ' қисми учун ',
    search2: ' та файл топилди ',
    product: 'Продукт',
    year: 'Год',
    qty: 'Кол-во',
  },
  main: {
    greeting: 'Здраствуйте',
    appname: 'Система Управления Технической Документацией',
  },
};

module.exports = ru;
