const uzc = {
  choose: 'Файл танлаш',
  upload: 'Юклаш',
  main_page: 'Асосий',
  admin_panel: 'Админ панел',
  drawings: 'Чизмалар',
  sertifikat: 'Сертификация',
  standart: 'Стандарт',
  loadingText: 'Юкланмоқда',
  noDataText: 'Маълумот топилмади',
  tr: '№',
  incorrect_login: 'Логин ёки пароль хато',
  rememberMe: 'Эслаб қолиш',
  search: 'Излаш',

  input: {
    required: 'Ушбу майдон мажбурий',
    text: 'Матн киритиш',
  },
  user: {
    index: 'Фойдаланувчилар',
    tbn: 'Табел рақами',
    username: 'Фойдаланувчи номи',
    name: 'Исм шарифи',
    email: 'Email',
    add: 'Фойдаланувчи қўшиш',
    created: 'Қўшилган',
    updated: 'Янгиланган',
    logs: 'Логлар',
    system: 'Тизим',
    controller: 'Ҳаракат',
    actions: 'Сўров',
    logtime: 'Вақти',
  },
  drawing: {
    upload: 'MPL Юклаш',
    mplupload: 'Янги MPL юклаш',
    name: 'Исми шарифи',
    email: 'Email',
    add: 'Фойдаланувчи қўшиш',
    created: 'Қўшилган',
    updated: 'Янгиланган',
  },
  mpl: {
    partnum: 'Детал рақами',
    product: 'Маҳсулот',
    year: 'Йили',
    qty: 'Сони',
    version: 'Версияси',
    compare: 'Changelog',
    funpartmod: 'Функционал номи',
    partname: 'Детал номи',
    drawingno: 'Чизма рақами',
    dwgrevdate: 'Чизма санаси',
    engineername1: 'Муҳандис исми1',
    engineergroup1: 'Муҳандис гуруҳи1',
    engineername2: 'Муҳандис исми2',
    engineergroup2: 'Муҳандис гуруҳи2',
    ewo: 'EWO',
    model: 'Модель',
    options: 'Турлари',
    firstyear: 'Бошланиш йили',
    lastyear: 'Якунланиш йили',
    pompl: 'MPL бўйича',
    inmpl: 'MPL',
    inftp: 'FTP',
  },
  part: {
    search: ' қисми учун ',
    search2: ' та файл топилди ',
    product: 'Маҳсулот',
    year: 'Йили',
    qty: 'Сони',
  },
  main: {
    greeting: 'Ассалому алайкум',
    appname: 'Техник Ҳужжатларни Бошқариш Тизими',
  },
};

module.exports = uzc;
