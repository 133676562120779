const eng = {
  choose: 'Choose File',
  upload: 'Upload',
  main_page: 'Main',
  admin_panel: 'Administrator',
  drawings: 'Drawings',
  sertifikat: 'Certifications',
  standart: 'Standarts',
  loadingText: 'Loading',
  noDataText: 'No data',
  tr: '№',
  incorrect_login: 'Incorrect Login or password',
  rememberMe: 'Remember me',
  search: 'Search',

  input: {
    required: 'This field required',
    text: 'Enter text',
  },
  user: {
    index: 'Users',
    tbn: 'Tabel number',
    username: 'Username',
    name: 'Fullname',
    email: 'Email',
    add: 'Add User',
    created: 'Added',
    updated: 'Updated',
    logs: 'Logs',
    system: 'System',
    controller: 'Action',
    actions: 'Request',
    logtime: 'Time',
  },
  drawing: {
    upload: 'Upload MPL',
    mplupload: 'Upload New MPL',
    name: 'Fullname',
    email: 'Email',
    add: 'Add User',
    created: 'Added',
    updated: 'Updated',
  },
  mpl: {
    partnum: 'Detal raqami',
    product: 'Mahsulot',
    year: 'Yili',
    qty: 'Soni',
    version: 'Versiyasi',
    compare: 'Changelog',
    funpartmod: 'Funksional nomi',
    partname: 'Detal nomi',
    drawingno: 'Chizma raqami',
    dwgrevdate: 'Chizma sanasi',
    engineername1: 'Muhandis ismi1',
    engineergroup1: 'Muhandis guruhi1',
    engineername2: 'Muhandis ismi2',
    engineergroup2: 'Muhandis guruhi2',
    ewo: 'EWO',
    model: 'Model',
    options: 'Turlari',
    firstyear: 'Boshlanish yili',
    lastyear: 'Yakunlanish yili',

    inmpl: 'MPL',
    inftp: 'FTP',
    // pomodel: 'Model bo`yicha',
  },
  part: {
    search: ' for this product ',
    search2: ' was founded ',
    product: 'Product',
    year: 'Year',
    qty: 'Qty',
  },
  main: {
    greeting: 'Welcome',
    appname: 'Technical Documents Management System',
  },
};

module.exports = eng;
